import React, { useMemo } from 'react';
import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * @type {AppContextType}
 */
const defaultContext = {
  accountSettings: {},
  homeInfo: {},
  userInfo: {},
  portals: {
    portals: [],
  },
  theme: {},
  workMode: {},
  v2Info: {},
  addClosingFunction: () => {},
};

/**
 * @typedef {Object} SectionPropType
 * @property {string} contextType
 * @property {string} nameKey
 * @property {string} href
 */

/**
 * @typedef {Object} LogoType
 * @property {?function} homeCallback
 * @property {string} homeDefaultDestination
 * @property {?string} logoHref
 * @property {?boolean} isCustomLogoEnabled
 * @property {?string} logoUrl
 */

/** @typedef ParticipantProps
 * @property {{selected: boolean, label: string, value: string}[]} menu
 * @property {func} action
 */

/**
 * @typedef {Object} UserPropertiesType
 * @property {string} userId
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} brandId
 * @property {string} brandName
 * @property {string} language
 * @property {string} accountType
 * @property {boolean} isFreeAccount
 * @property {?ParticipantProps} languageMenu360
 */

/**
 * @typedef {Object} ThemeType
 * @property {?string} color
 */

/** @typedef {Object} PortalsInfoType
 * @property {?Array.<Object>} portals
 * @property {boolean} displayHelpModalInPortal
 * @property {boolean} isLegacyPortalHeader
 */

/** @typedef {Object} WorkModeType
 * @property {boolean} isF6NavigationEnabled
 * @property {boolean} debugMode
 * @property {boolean} isExternalAppMode
 * @property {boolean} restrictedNavigation
 * @property {boolean} areImportantMessagesEnabled
 * @property {boolean} isAuthnLogoutEnabled
 */

/** @typedef {Object} V2InfoType
 * @property {string} pageId
 * @property {{resourceId: string, resourceType: string, subresourceId: string}} context
 */

/**
 * @typedef {Object} AppContextType
 * @property {SectionPropType} accountSettings
 * @property {LogoType} homeInfo
 * @property {UserPropertiesType} userInfo
 * @property {PortalsInfoType} portals
 * @property {ThemeType} theme
 * @property {WorkModeType} workMode
 * @property {V2InfoType} v2Info
 * @property {{label: string, onSelect?: func, href?: string}[]} accountOptions
 * @property {function} addClosingFunction
 */

/**
 * @type {import("react").Context<AppContextType>}
 */
export const AppContext = createContext(defaultContext);

/**
 * @typedef {Object} AppContextProviderProps
 * @property {import('react').ReactNode|import('react').ReactNode[]} children
 * @property {AppContextType} initialState
 * /


/**
 * @type {import("react").FC<AppContextProviderProps>}
 */
export const AppContextProvider = ({ children, initialState, logoUrl }) => {
  const state = initialState || defaultContext;
  const contextValue = useMemo(
    () => ({
      ...state,
      homeInfo: {
        ...state.homeInfo,
        logoUrl,
      },
    }),
    [state, logoUrl],
  );
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.element,
  logoUrl: PropTypes.string,
  initialState: PropTypes.object,
};
