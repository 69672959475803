/* eslint-disable react/no-unknown-property */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './top-level-nav.scss';
import Home from './Home/Home';
import Help from './Help/Help';
import AccountMenu from './AccountMenu/AccountMenu';
import Notifications from './Notifications/Notifications';
import Switcher from './Switcher/Switcher';
import { getTier1DataTestId } from '../Service/testing';
import PersonLanguage from './PersonLanguage/PersonLanguage';
import { messageService } from '../Service/message-service';
import WaffleMenu from './WaffleMenu/WaffleMenu';
import { useScreenType } from './hooks/useScreenType';
import classNames from 'classnames';
import { shouldHideTier1NavInChildIframe } from '../Service/program-child-checker-service';
import { ImportantMessagesBadge } from './ImportantMessages';
import { RestrictedNavigationTopLevelNav } from './RestrictedNavigationTopLevelNav/RestrictedNavigationTopLevelNav';
import { AccountSettingsTopLevelNav } from './AccountSettingsTopLevelNav';
import { usePortalsInfo, useTheme, useV2Info, useWorkMode } from '../Context';
import { ConditionallyRenderedBreadcrumb } from './ConditionallyRenderedBreadcrumb';

const zIndex = 2000;
export default function TopLevelNav({
  reactBreadcrumb,
  angularBreadcrumb,
  pageId,
}) {
  const { screenType, isSmallScreen, isMobileView } = useScreenType();
  const {
    isExternalAppMode,
    areImportantMessagesEnabled,
    isF6NavigationEnabled,
    restrictedNavigation,
  } = useWorkMode();
  const {
    isPortalsApplication,
    isParticipantPortal,
    isPortalsChild,
    isCookieInPortalMode,
    displayHelpModalInPortal,
  } = usePortalsInfo();
  const { context } = useV2Info();
  const { color } = useTheme();

  const hideSwitcher = useMemo(
    () =>
      isExternalAppMode ||
      isParticipantPortal ||
      isPortalsApplication ||
      context?.contextType === 'AccountSettingsV2',
    [isExternalAppMode, isParticipantPortal, isPortalsApplication, context],
  );

  const breadcrumbBeforeLogo = isPortalsApplication && isSmallScreen;
  const isPortalsMobileView = isPortalsApplication && isMobileView;
  const shouldDisplayNotifications = useMemo(
    () => !isExternalAppMode,
    [isExternalAppMode],
  );
  const shouldDisplayHelp = useMemo(
    () =>
      !isExternalAppMode &&
      (!isPortalsApplication || !!displayHelpModalInPortal),
    [isExternalAppMode, isPortalsApplication, displayHelpModalInPortal],
  );

  if (isPortalsChild || shouldHideTier1NavInChildIframe()) {
    return (
      <nav
        data-testid={getTier1DataTestId('NavContainer')}
        className={styles.topLevelNavPortalsChild}
        aria-hidden={true}
      />
    );
  }

  if (restrictedNavigation) {
    return (
      <RestrictedNavigationTopLevelNav
        conditionallyRenderedBreadcrumb={
          <ConditionallyRenderedBreadcrumb
            reactBreadcrumb={reactBreadcrumb}
            angularBreadcrumb={angularBreadcrumb}
            pageId={pageId}
          />
        }
      />
    );
  }
  // temporary workaround until new Account Settings are delivered
  if (
    context?.contextType === 'AccountSettings' ||
    context?.contextType === 'AccountSettingsSectionV2'
  ) {
    if (isCookieInPortalMode) {
      return <AccountSettingsTopLevelNav zIndex={zIndex} />;
    }
  }

  return (
    <nav
      data-testid={getTier1DataTestId('NavContainer')}
      className={classNames(
        isPortalsApplication
          ? styles.topLevelNavPortalsApplication
          : styles.topLevelNav,
        styles[screenType],
      )}
      aria-label={messageService.get('PRIMARY')}
      q-region-index={isF6NavigationEnabled ? '0' : undefined}
      style={color ? { boxShadow: 'inset 0px -2px 0 0 ' + color } : undefined}
    >
      <div
        className={classNames(
          styles.leftSection,
          isPortalsApplication ? styles[screenType] : undefined,
        )}
      >
        {breadcrumbBeforeLogo && (
          <ConditionallyRenderedBreadcrumb
            reactBreadcrumb={reactBreadcrumb}
            angularBreadcrumb={angularBreadcrumb}
          />
        )}
        {!isPortalsMobileView && <Home />}
        {hideSwitcher ? undefined : <Switcher zIndex={zIndex} />}

        {!breadcrumbBeforeLogo && (
          <ConditionallyRenderedBreadcrumb
            reactBreadcrumb={reactBreadcrumb}
            angularBreadcrumb={angularBreadcrumb}
          />
        )}
      </div>

      {isPortalsMobileView && <Home />}

      <div className={classNames(styles.rightSection, styles[screenType])}>
        {areImportantMessagesEnabled && <ImportantMessagesBadge />}
        <PersonLanguage zIndex={zIndex} />
        {shouldDisplayHelp && <Help />}
        {shouldDisplayNotifications && <Notifications />}
        <AccountMenu
          zIndex={zIndex}
          shouldDisplayHelp={shouldDisplayHelp}
          shouldDisplayNotifications={shouldDisplayNotifications}
        />
        <WaffleMenu zIndex={zIndex} />
      </div>
    </nav>
  );
}

TopLevelNav.propTypes = {
  reactBreadcrumb: PropTypes.element,
  angularBreadcrumb: PropTypes.object,
  pageId: PropTypes.string,
};
