import React, { useEffect, useState } from 'react';
import SkipToMainContentLink from '../../Accessibility/SkipToMainContentLink/SkipToMainContentLink';
import PropTypes from 'prop-types';
import { usePortalsInfo, useWorkMode } from '../../Context';

export default function SkipToMainContentAttacher({ mainContentId }) {
  const { isPortalsChild, isPortalsApplication } = usePortalsInfo();
  const { isF6NavigationEnabled } = useWorkMode();
  const [shouldShowSkipToMainContentLink, setShouldShowSkipToMainContentLink] =
    useState(false);
  const skipToMainContentTabHandler = (event) => {
    if (
      event.key === 'Tab' &&
      !shouldShowSkipToMainContentLink &&
      (!!document.querySelector('[role="main"]') ||
        !!document.getElementsByTagName('main').length > 0)
    ) {
      document.removeEventListener(
        'keydown',
        skipToMainContentTabHandler,
        false,
      );
      setShouldShowSkipToMainContentLink(true);
    }
  };

  const enableSkipToMainContent = () => {
    if (!isF6NavigationEnabled || isPortalsChild) {
      return;
    }

    if (mainContentId) {
      setShouldShowSkipToMainContentLink(true);
    } else {
      document.addEventListener('keydown', skipToMainContentTabHandler, false);
    }
  };

  useEffect(() => {
    enableSkipToMainContent();
  }, []);

  return shouldShowSkipToMainContentLink ? (
    <SkipToMainContentLink
      mainContentId={mainContentId}
      isPortalsApplication={isPortalsApplication}
    />
  ) : null;
}

SkipToMainContentAttacher.propTypes = {
  mainContentId: PropTypes.string,
};
