import { messageService } from '../Service/message-service';
import cookies from '../Service/cookies';
import { buildUrlWithoutNullishParams } from '@qualtrics/page-http-client/lib/getQueryString';
import { useCallback, useEffect, useState } from 'react';

const getLogoUrl = (theme, optionsTheme, isCustomLogoEnabled, sessionId) => {
  const brandLogoURL = buildUrlWithoutNullishParams('/brand-management/logo', {
    udsSessionKey: sessionId,
  });

  const currentTheme = theme || optionsTheme || {};
  return (
    currentTheme.logoURL ||
    currentTheme.logoUrl ||
    (isCustomLogoEnabled ? brandLogoURL : undefined)
  );
};

const getAccountMenuOptions = (customAccountMenuOption, accountMenuOptions) => {
  return (
    accountMenuOptions ??
    (customAccountMenuOption
      ? [
          {
            label: customAccountMenuOption.label,
            onSelect: customAccountMenuOption.action,
            href: undefined,
          },
        ]
      : undefined)
  );
};

export const useContextSetup = (props) => {
  const { options = {} } = props;
  const optionsTheme = options.theme;
  const [readyToRender, setReadyToRender] = useState(false);
  const [translations, setTranslations] = useState({});
  const [logoUrl, setLogoUrl] = useState();

  useEffect(() => {
    if (optionsTheme) {
      setLogoUrl(
        getLogoUrl(
          props.theme,
          optionsTheme,
          appContext && appContext.workMode.isCustomLogoEnabled,
          sessionId,
        ),
      );
    }
  }, [optionsTheme]);

  const [appContext, setAppContext] = useState(
    /** @type {import('../Context/AppContext').AppContextType} */ null,
  );

  const sessionId = cookies.get('UDSSessionKey');
  const setUpContext = useCallback(
    (config) => {
      const defaultCallback = props.homeHref
        ? () => {
            window.location.href = props.homeHref;
          }
        : undefined;

      setLogoUrl(
        getLogoUrl(
          props.theme,
          {
            logoUrl,
            ...optionsTheme,
          },
          config.isCustomLogoEnabled,
          sessionId,
        ),
      );
      setAppContext({
        userInfo: {
          userId: config.userData.userId,
          firstName: config.userData.firstName,
          lastName: config.userData.lastName,
          brandId: config.userData.brandId,
          brandName: config.userData.brandName,
          language: config.userData.language,
          accountType: config.userData.accountType,
          isFreeAccount: config.isFreeAccount,
        },
        portals: {
          portals: config.portals,
          displayHelpModalInPortal: config.displayHelpModalInPortal,
          isLegacyPortalHeader: props.isPortal,
          participantProps: options.personLanguage,
        },
        workMode: {
          isF6NavigationEnabled: config.enableSkipToMainContent,
          debugMode: props.debugMode,
          isExternalAppMode: options.isExternalAppMode,
          restrictedNavigation: config.restrictedNavigation,
          areImportantMessagesEnabled: config.isImportantMessagesEnabled,
          isAuthnLogoutEnabled: config.isAuthnLogoutEnabled,
          isCustomLogoEnabled: config.isCustomLogoEnabled,
        },
        homeInfo: {
          isCustomLogoEnabled: config.isCustomLogoEnabled,
          homeDefaultDestination:
            config.homeDefaultDestination || '/Q/MyProjectsSection',
          logoHref: options.logoHref,
          homeCallback: options.homeCallback ?? defaultCallback,
        },
        v2Info: config.v2Info || {},
        accountOptions: getAccountMenuOptions(
          options.customAccountMenuOption,
          props.accountMenuOptions,
        ),
        theme: {
          color: props.theme?.color,
        },
        addClosingFunction: props.addClosingFunction,
      });

      setReadyToRender(true);
      setTranslations({
        'menu.noItemsToDisplay': messageService.get('NO_ITEMS_TO_DISPLAY'),
        searchText: messageService.get('SEARCH_PROMPT'),
      });
    },
    [options, sessionId, props, optionsTheme],
  );

  return { readyToRender, translations, setUpContext, appContext, logoUrl };
};
